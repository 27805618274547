/**
 * Starts when DOM is Ready
 */
jQuery(function ($) {
    /**
     * Table with column actions
     */
    $('.table.with-action tbody tr').has('.row-action').click(function (event) {
        var element = $(event.target);

        if (element.hasClass('btn') || element.prop('tagName') == 'I' || element.prop('tagName') == 'A') {
            return true;
        }

        if (element.hasClass('skip-row-action') || element.closest('.skip-row-action').length > 0) {
            return true;
        }

        var link = $(this).find('.row-action').first();
        var url = link.prop('href');
        var target = link.prop('target');

        if (!target) {
            target = '_self';
        }

        window.open(url, target);
    });

    /**
     * Init rest buttons
     */
    $('.btn-rest').restfulizer({
        parse: true
    });

    /**
     * Form confirm action
     */
    $('form[data-confirm]').submit(function (e) {
        var form = $(this);

        if (form.data('force-submit')) {
            return true;
        }

        e.preventDefault();

        swal({
            title : 'Atenção!',
            text: $(this).data('confirm') || 'Você tem certeza disso?',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: false
        }, function(isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Aguarde!',
                    text: 'Estamos processando sua operação!',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 500
                }, function() {
                    form.data('force-submit', true);
                    form.submit();
                });
            } else {
                form.removeData('force-submit');

                swal({
                    title: 'Cancelado',
                    text: 'Você cancelou a operação!',
                    type: 'error',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        });
    });

    $('form textarea, form select, form input').on('change', function () {
        var form = $(this).closest('form');

        form.removeData('changed');
        form.data('changed', true);
    });

    var formConfirmDefaultMessage = 'O formulário foi modificado, se você continuar as modificações serão perdidas, deseja continuar?';

    $('a[data-confirm] form').on('submit', function (e) {
        var form = $(this);
        var a = form.closest('a');

        if (form.data('force-submit')) {
            return true;
        }

        e.preventDefault();

        swal({
            title: 'Atenção!',
            text: $(a).data('confirm') || 'Você tem certeza disso?',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: false
        }, function(isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Aguarde!',
                    text: 'Estamos processando sua operação!',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 500
                }, function() {
                    form.data('force-submit', true);
                    form.submit();
                });
            } else {
                form.removeData('force-submit');

                swal({
                    title: 'Cancelado',
                    text: 'Você cancelou a operação!',
                    type: 'error',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        });

        // if (confirm(a.data('confirm'))) {
        //     return true;
        // }
        //
        // e.preventDefault();
        //
        // return false;
    });

    $('.btn-confirm-form-changed form').on('submit', function (e) {
        var form = $(this);
        var button = form.closest('.btn-confirm-form-changed');
        var message = button.data('form-confirm') || formConfirmDefaultMessage;

        if (!button.closest('form').data('changed')) {
            return true;
        }

        if (form.data('force-submit')) {
            return true;
        }

        e.preventDefault();

        swal({
            title: 'Atenção!',
            text: message,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: false
        }, function(isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Aguarde!',
                    text: 'Estamos processando sua operação!',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 500
                }, function() {
                    form.data('force-submit', true);
                    form.submit();
                });
            } else {
                form.removeData('force-submit');

                swal({
                    title: 'Cancelado',
                    text: 'Você cancelou a operação!',
                    type: 'error',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        });

        //
        // if (confirm(button.data('form-confirm') || formConfirmDefaultMessage)) {
        //     return true;
        // }
        //
        // e.preventDefault();
        //
        // return false;
    });

    $('.btn-confirm-form-changed').on('click', function (e) {
        var button = $(this);
        var message = button.data('form-confirm') || formConfirmDefaultMessage;

        if (button.find('form').length > 0) {
            return true;
        }

        if (!button.closest('form').data('changed')) {
            return true;
        }

        var href = button.attr('href');
        var target = button.attr('target') || '_self';

        e.preventDefault();

        swal({
            title: 'Atenção!',
            text: message,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: 'Sim',
            cancelButtonText: "Cancelar",
            closeOnConfirm: false,
            closeOnCancel: false
        }, function(isConfirm) {
            if (isConfirm) {
                swal({
                    title: 'Aguarde!',
                    text: 'Estamos processando sua operação!',
                    type: 'info',
                    showConfirmButton: false,
                    timer: 500
                }, function() {
                    window.open(href, target);
                });
            } else {
                swal({
                    title: 'Cancelado',
                    text: 'Você cancelou a operação!',
                    type: 'error',
                    showConfirmButton: true,
                    timer: 3000
                });
            }
        });
    });

    /**
     * Init select2
     */
    $('.select2').each(function () {
        var placeholder = $(this).data('placeholder');

        $(this).select2({
            'language': 'pt-BR',
            'allowClear': true,
            'placeholder': (typeof placeholder != 'undefined') ? placeholder : ':: Selecione um item ::'
        });
    });

    /**
     * Table count columns
     */
    $('.colspan').each(function () {
        var table = $(this).closest('table');

        $(this).attr('colspan', table.columns());
    });

    /**
     * Redirect back.
     */
    $('.btn-cancel, .btn-back').click(function (e) {
        var ref = document.referrer + "";
        var current = location.href;

        if (ref.startsWith(base_url) && ref != current) {
            e.preventDefault();

            history.back();
        }
    });

    /**
     * Mask inputs
     */
    $('.mask-cpf')
        .mask('000.000.000-00', {reverse: true, clearIfNotMatch: false});

    $('.mask-date')
        .mask('00/00/0000', {clearIfNotMatch: false});

    $('.mask-phone')
        .mask(function (phone) {
            return phone.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
        }, {clearIfNotMatch: true});
});

/**
 * Count table number of columns.
 * @param {boolean} refresh Indicates whenever should refresh the count.
 *
 * @returns {int}
 */
jQuery.fn.columns = function (refresh) {
    var total = 0;
    var $table = $(this);
    var shouldRefresh = typeof refresh != 'undefined' && refresh === true;
    var hasTotal = typeof $table.data('total_columns') != 'undefined';

    if (hasTotal && !shouldRefresh) {
        return $table.data('total_columns');
    }

    $('thead tr:nth-child(1) th, thead tr:nth-child(1) td', $table).each(function () {
        if ($(this).attr('colspan')) {
            total += parseInt($(this).attr('colspan'));
        } else {
            total++;
        }
    });

    $table.data('total_columns', total);

    return total;
};

/**
 * Serialize a form into a object.
 *
 * @returns {Object}
 */
jQuery.fn.serializeObject = function () {
    var data = $(this).serializeArray();
    var results = {};

    for (var i in data) {
        results[data[i].name] = data[i].value;
    }

    return results;
};

/**
 * Compare two objects for equality.
 * @param {Object} a
 * @param {Object} b
 * @param {undefined|boolean}recursive
 * @returns {boolean}
 */
jQuery.objectEquals = function (a, b, recursive) {
    recursive = (typeof recursive) == 'undefined' ? true : recursive == true;

    if (a == b) {
        return true;
    }

    if (Object.keys(a).length != Object.keys(b).length) {
        return false;
    }

    for (var k in a) {
        if (!b.hasOwnProperty(k)) {
            return false;
        }

        if ((a[k] instanceof Object) && (b[k] instanceof Object) && recursive) {
            if (!arguments.callee(a[k], b[k])) {
                return false;
            }
        }
        else if (b[k] != a[k]) {
            return false;
        }
    }

    return true;
};

/**
 * @param {String} url
 * @param {Object|Array} params
 * @returns {String}
 */
function appendUrlParams(url, params) {
    if (typeof params == 'undefined' || isEmpty(params)) {
        return url;
    }

    if (!/\?/.test(url)) {
        url = url + '?';
    }

    var parsed = $.param(params);

    url = /\?$/.test(url) ? url + parsed : url + '&' + parsed;

    return url.replace(/\?$/, '');
}

/**
 * @param {String} to
 */
function redirect(to) {
    window.location.assign(to);
}

/**
 * @param obj
 * @returns {boolean}
 */
function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }

    return true;
}

function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf == '') return false;
    // Elimina CPFs invalidos conhecidos
    if (cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999")
        return false;
    // Valida 1o digito
    add = 0;

    for (i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }

    if (rev != parseInt(cpf.charAt(9))) {
        return false;
    }

    // Valida 2o digito
    add = 0;

    for (i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
    }

    rev = 11 - (add % 11);

    if (rev == 10 || rev == 11) {
        rev = 0;
    }

    return rev == parseInt(cpf.charAt(10));
}